const routesConfig = {
  allReservations: {
    route: "/administration/all-reservations",
    component: "AllReservations"
  },
  editReservation: {
    route: "/administration/edit-reservation",
    params: "/:id",
    component: "EditReservation"
  },
  addReservation: {
    route: "/administration/add-reservation",
    component: "AddReservation"
  }
};

export const routesArray = Object.values(routesConfig).map(({ route }) => route);
export const routesObjects = [];
Object.keys(routesConfig).forEach(routeKey => {
  routesObjects.push({ key: routeKey, ...routesConfig[routeKey] });
});
export default routesConfig;
