exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-administration-js": () => import("./../../../src/pages/administration.js" /* webpackChunkName: "component---src-pages-administration-js" */),
  "component---src-pages-beaches-ammos-beach-bar-js": () => import("./../../../src/pages/beaches/ammos-beach-bar.js" /* webpackChunkName: "component---src-pages-beaches-ammos-beach-bar-js" */),
  "component---src-pages-beaches-blue-beach-bar-js": () => import("./../../../src/pages/beaches/blue-beach-bar.js" /* webpackChunkName: "component---src-pages-beaches-blue-beach-bar-js" */),
  "component---src-pages-beaches-breeze-beach-bar-js": () => import("./../../../src/pages/beaches/breeze-beach-bar.js" /* webpackChunkName: "component---src-pages-beaches-breeze-beach-bar-js" */),
  "component---src-pages-beaches-elies-beach-js": () => import("./../../../src/pages/beaches/elies-beach.js" /* webpackChunkName: "component---src-pages-beaches-elies-beach-js" */),
  "component---src-pages-beaches-gefyraki-beach-bar-js": () => import("./../../../src/pages/beaches/gefyraki-beach-bar.js" /* webpackChunkName: "component---src-pages-beaches-gefyraki-beach-bar-js" */),
  "component---src-pages-beaches-kabata-beach-js": () => import("./../../../src/pages/beaches/kabata-beach.js" /* webpackChunkName: "component---src-pages-beaches-kabata-beach-js" */),
  "component---src-pages-beaches-koo-beach-bar-js": () => import("./../../../src/pages/beaches/koo-beach-bar.js" /* webpackChunkName: "component---src-pages-beaches-koo-beach-bar-js" */),
  "component---src-pages-beaches-laroca-beach-bar-js": () => import("./../../../src/pages/beaches/laroca-beach-bar.js" /* webpackChunkName: "component---src-pages-beaches-laroca-beach-bar-js" */),
  "component---src-pages-beaches-liquid-beach-bar-js": () => import("./../../../src/pages/beaches/liquid-beach-bar.js" /* webpackChunkName: "component---src-pages-beaches-liquid-beach-bar-js" */),
  "component---src-pages-beaches-paradise-beach-bar-js": () => import("./../../../src/pages/beaches/paradise-beach-bar.js" /* webpackChunkName: "component---src-pages-beaches-paradise-beach-bar-js" */),
  "component---src-pages-beaches-peponi-beach-bar-js": () => import("./../../../src/pages/beaches/peponi-beach-bar.js" /* webpackChunkName: "component---src-pages-beaches-peponi-beach-bar-js" */),
  "component---src-pages-beaches-ramos-beach-bar-js": () => import("./../../../src/pages/beaches/ramos-beach-bar.js" /* webpackChunkName: "component---src-pages-beaches-ramos-beach-bar-js" */),
  "component---src-pages-beaches-riva-beach-project-js": () => import("./../../../src/pages/beaches/riva-beach-project.js" /* webpackChunkName: "component---src-pages-beaches-riva-beach-project-js" */),
  "component---src-pages-beaches-vrasidas-beach-js": () => import("./../../../src/pages/beaches/vrasidas-beach.js" /* webpackChunkName: "component---src-pages-beaches-vrasidas-beach-js" */),
  "component---src-pages-boat-info-js": () => import("./../../../src/pages/boat-info.js" /* webpackChunkName: "component---src-pages-boat-info-js" */),
  "component---src-pages-boat-trip-to-elies-beach-js": () => import("./../../../src/pages/boat-trip-to-elies-beach.js" /* webpackChunkName: "component---src-pages-boat-trip-to-elies-beach-js" */),
  "component---src-pages-boat-trip-to-fidonisi-js": () => import("./../../../src/pages/boat-trip-to-fidonisi.js" /* webpackChunkName: "component---src-pages-boat-trip-to-fidonisi-js" */),
  "component---src-pages-boat-trip-to-laroca-js": () => import("./../../../src/pages/boat-trip-to-laroca.js" /* webpackChunkName: "component---src-pages-boat-trip-to-laroca-js" */),
  "component---src-pages-boat-trip-to-vrasidas-js": () => import("./../../../src/pages/boat-trip-to-vrasidas.js" /* webpackChunkName: "component---src-pages-boat-trip-to-vrasidas-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-forgotten-password-js": () => import("./../../../src/pages/forgotten-password.js" /* webpackChunkName: "component---src-pages-forgotten-password-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-login-without-password-js": () => import("./../../../src/pages/login-without-password.js" /* webpackChunkName: "component---src-pages-login-without-password-js" */),
  "component---src-pages-login-without-password-success-js": () => import("./../../../src/pages/login-without-password-success.js" /* webpackChunkName: "component---src-pages-login-without-password-success-js" */),
  "component---src-pages-map-of-nea-peramos-js": () => import("./../../../src/pages/map-of-nea-peramos.js" /* webpackChunkName: "component---src-pages-map-of-nea-peramos-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-sign-up-js": () => import("./../../../src/pages/sign-up.js" /* webpackChunkName: "component---src-pages-sign-up-js" */),
  "component---src-pages-workflow-js": () => import("./../../../src/pages/workflow.js" /* webpackChunkName: "component---src-pages-workflow-js" */)
}

